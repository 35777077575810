<script src="./index.js"></script>
<style src="./style.scss" lang="scss" scoped/>
<template>
  <div id="main-container" class="container" v-bind:class="{ 'open-map-mobile': openMapMobile }">
    <div class="row  d-flex" v-if="properties !== null">
      <div class="col-md-6 search-results-list" v-if="properties.length > 0">=
        <div class="row search-results-nav">
          <SearchResultsNav @sort="sortResults" :resultsamount="properties.length" />
        </div>
        <div class="row property-results-container">
          <div class="col-md-6 propert-ad" v-for="property in properties">
            <div v-bind:ref="'prop' + property.id">
              <PropertyAd :property="property" :isSelected="selectedMarker === property.id" :adtype="property.isVip ? 'premium' : 'regular'"
                :city="property.city.name" :district="property.district.name" :title="property.title" :amount="property.price"
                :images="property.images" :id="property.id" :key="'p' + property.id" @click="selectMarker(property.id)" />
            </div>
          </div>
        </div>
        <div class="show-more-results" v-if="hasMoreResults">
          <Button :theme="'solid-grey'" :size="'medium'" :text="isMoreResultsLoading ? $t('searching') : $t('show-more-results')" @click="showMoreResults()"
            :svg="isMoreResultsLoading ? 'loading' : 'none'"/>
        </div>
      </div>
      <div class="col-md-6 search-results-list" v-else>
        <div class="alert alert-danger" role="alert">
          {{ $t('no-search-results-found') }}

        </div>
        <Button @click="toggleAdvancedSearchModal()" class="col-3" :theme="'outline-orange'" :size="'large'"
                :text="$t('new-search')" :svg="'extendedsearch'" />
      </div>
      <div class="col-md-6 search-results-map">
        <GoogleMap style="width: 100%;
                   height: 100%" api-key="AIzaSyB4WqbVck19lMj4zvEBU9Y_CPILPW_237Q" :zoom="mapZoom" :center="mapCenter"
          :styles="mapOption" :disableDefaultUi="true">
          <Marker v-for="(marker, index) in markers" v-bind:key="'mk' + index" v-bind:ref="'mk' + marker.propertyId"
            :options="marker" v-on:click="selectProperty(marker.propertyId)" />
        </GoogleMap>
      </div>
    </div>
    <PreLoader v-else/>
    <div class="extended-search-modal">
      <AdvancedSearchModal ref="advancedSearchModal" />
    </div>
    <div class="d-md-none map-button">
      <div class="button-show-map">
        <Button :theme="'solid-orange'" :size="'medium'" :text="$t('show-list')" :svg="'list'"
                v-on:click="toggleMapMobile" />
      </div>
      <div class="button-show-list">
        <Button class="map-button1" :theme="'solid-orange'" :size="'medium'" :text="$t('show-map')" :svg="'map'"
                v-on:click="toggleMapMobile" />
      </div>
    </div>
  </div>
  <div class="d-md-none">
    <Footer />
  </div>
</template>

